import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Presentation from "../components/Presentation"
import globalVariables from "../components/globalVariables"

const Section = styled.div`
  padding: 40px 0;
  position: relative;
  @media (min-width: ${globalVariables.medDesktop}) {
    padding: 0;
  }
`

const Text = styled.div`
  display: flex;
  z-index: 0;
  height: 100%;
  opacity: 0.1;
  color: ${props => props.theme.grey};
  position: absolute;
  font-size: 200px;
  font-weight: 700;
  width: 100%;
  top: 0;
  @media (min-width: ${globalVariables.minTablet}) {
    z-index: -1;
    left: 50%;
    width: 50%;
  }
`

const NotFoundPage = ({ data }) => {
  return (
    <Section>
      <Presentation
        histoire={data.imageExpertGauche.fluid}
        title="Page introuvable"
        text="La page que vous recherchez n’existe pas..."
        buttonText="Retour sur le site du Collectif"
        buttonUrl="/"
      />
      <Text>404</Text>
    </Section>
  )
}

export default NotFoundPage

export const query = graphql`
  query {
    imageExpertGauche: file(relativePath: { eq: "LesExpertsGauche.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
